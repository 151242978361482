@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: 'optima';
  src: url('./assets/fonts/optima/optima-bold.ttf') format('truetype');
}
@font-face {
  font-family: 'calibari';
  src: url('./assets/fonts/calibari/calibri-regular.ttf') format('truetype');
}


:root {


  --color-primary: #f5811e;
  --color-secondary: #071b40;
  --color-light: #fffaf6;
  --coloor-dark: #050606;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.344); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary); 
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary); 
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
 
  background-color: var(--color-light);
  overflow-x: hidden;
  font-family: 'calibari', sans-serif;
}
.nav-link{
  font-weight: 500;
}
.nav-link:hover,.active{
  color: var(--color-primary);
}

/* global */
p,h1,h2,h3,h4,h5,h6{
  margin: 0;
}
a{
  text-decoration: none;
  color: var(--coloor-dark);
}
ul{
  list-style: none;
}
.widget-title{
  position: relative;
  font-size: 20px;
  font-weight: 800;
  color: white;
  margin-bottom: 30px;
  font-family: 'optima', sans-serif;
}
.widget-title::after{
  content: '';
  width: 30px;
  height: 5px;
  background-color: var(--color-primary);
  display: flex;
  border-radius: 2px;
  transform: translateY(5px);
  
}
.text,.post-wrapper p,.post-wrapper li{
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
}
.text-bg{
  font-size: 19px;
  font-weight: 500;
  line-height: 24px;
}
.btn{
  border-radius: 20px;
  padding: 6px 25px;
  font-weight: 500;
  font-size: 16px;
  font-family: 'optima', sans-serif;
}
.range{
  width: 100%;
  height: auto !important;
}
.fields{
  align-items: flex-end;
}





.range::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none; /* Remove default styling */
  width: 20px; /* Thumb size */
  height: 20px; /* Thumb size */
  background: #ff5733; /* Thumb color */
  border-radius: 50%; /* Round thumb */
  cursor: pointer; /* Change cursor */
}

.range::-moz-range-thumb {
  width: 20px; /* Thumb size */
  height: 20px; /* Thumb size */
  background: #ff5733; /* Thumb color */
  border: none; /* Remove border */
  border-radius: 50%; /* Round thumb */
  cursor: pointer; /* Change cursor */
}

.range::-ms-thumb {
  width: 20px; /* Thumb size */
  height: 20px; /* Thumb size */
  background: #ff5733; /* Thumb color */
  border: none; /* Remove border */
  border-radius: 50%; /* Round thumb */
  cursor: pointer; /* Change cursor */
}




.emi-user-value{
  font-weight: 500;
  font-size: 16px;
  font-family: 'optima', sans-serif;
  color: var(--color-primary);
}
.btn:nth-child(2){
  margin-left: 15px;
  font-family: 'optima', sans-serif;
}
.btn-primary{
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.btn-primary:hover{
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}
.btn-primar-outline{
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
}
.btn-primar-outline:hover{
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: white;
}
.heading-primary,.post-wrapper h1{
  font-size: 40px;
  font-weight: 800;
  color: var(--coloor-dark);
  font-family: 'optima', sans-serif;
}
.color-primary{
  color: var(--color-primary);
}
.secondary-heading,.post-wrapper h2{
  font-weight: 800;
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'optima', sans-serif;
}
.craousel-btn{
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  font-size: 35px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: .4s ease-in-out;
  position: absolute;
  right: 0;
  top: -100px;
}
.craousel-btn-prev{
  right: 55px;
}
.craousel-btn:hover{
  border-color: var(--color-secondary);
  color: var(--color-secondary);
}
.color-light{
  color: white;
}
.accent-heading{
  font-size: 18px;
  font-weight: 600;
  color: var(--coloor-dark);
  font-family: 'optima', sans-serif;
}
.page-header-main{
  color: white;
  font-size: 55px;
  font-weight: 700;
  font-family: 'optima', sans-serif;
}

/* header */
header {
  padding: 0 70px;
  position: absolute;
  width: 100%;
  top: 60px;
  z-index: 555;
}
nav{
  padding-left: 40px !important;
  padding-right: 40px !important;
  border-radius: 40px;
}
.navbar-nav{
  width: 100%;
  justify-content: center;
}
.nav-item{
  margin: 0 10px;
}

.megaenu-service{
  width: 1000px;
  left: 50%;
  transform: translateX(-50%);
  padding: 30px;
}
.dropdown:hover > .dropdown-menu{
  display: block;
}
.service-menu-item{
  border: 2px solid var(--color-light);
  padding: 15px;
  border-radius: 20px;
}
.service-menu-item:hover{
  border-color: var(--color-primary);
}
.service-menu-item:hover p{
  color: var(--color-primary);
}
.service-menu-item img{
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}
/* hero */
#hero,#page-header{
  padding: 30px;
  
  
}
#hero .inner,.page-header-inner{
  
  min-height: 650px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 30px;
  display: flex !important;
  align-items: center;
  background-color: #18181841;
  background-blend-mode: multiply;
}
#hero .slick-dots{
  right: 40px;
}
.page-header-inner{
  max-height: 350px !important;
  min-height: 250px !important;
  justify-content: center;
  align-items: end;
  padding-bottom: 50px;
  background-color: var(--color-secondary);
  background-image: url('https://kanakadurgafinance.com/assets/images/kanaka-durga-slider-01.jpg');
}
.hero-1{
  background-image: url('https://kanakadurgafinance.com/assets/images/kanaka-durga-slider-01.jpg');
}
.hero-2{
  background-image: url('https://kanakadurgafinance.com/assets/images/kanaka-durga-slider-02.jpg');
}
.hero-3{
  background-image: url('https://kanakadurgafinance.com/assets/images/kanaka-durga-slider-03.jpg');
}
.hero-4{
  background-image: url('https://kanakadurgafinance.com/assets/images/kanaka-durga-slider-04.jpg');
}
.hero-secondary-heading{
  font-size: 25px;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  
}
.hero-main-heading{
  margin: 30px 0;
  font-size: 50px;
  font-weight: 800;
  color: white;
  text-transform: uppercase;
  font-family: 'optima', sans-serif;
}

.contact-right{
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

/* tools */
#tools{
  padding-top: 0px;
}
.tool-item{
  width: 100%;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  transition: .4s ease-in-out;
  padding: 20px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, .1);
  background-color: white;
}
.tool-item:hover{
  background-color: var(--color-secondary);
}
.tool-item:hover p{
  color: white;
}
.tool-icon img{
  margin-right: 20px;
  height:70px;
  margin-bottom: 10px;
 

}


/* service */
#service{
  padding: 60px 0;
}
.service-item{
  background-color: var(--color-secondary);
  padding: 15px;
  border-radius: 20px;
 
}
.slick-slide{
  padding: 0 10px;
}
.service-img img{
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 15px;
}
.service-titele{
  margin: 20px 0;
}
.section-intro{
  margin-bottom: 40px;
}
.caouesl-btns{
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
  height: 100%;
}



/* partner */
#partner{
  padding: 40px 0;
  border-top: 2px solid #9e9e9e;
  border-bottom: 2px solid #9e9e9e;
}
.partner-wrapper{
  justify-content: space-between;
  align-items: center;
}
.partner-item-home img{
  height: 60px;
  width: auto;
}
.dropdown-menu li:hover{
  background-color: var(--color-primary);
}
.dropdown-menu li:hover a{
  color: white;
}


/* about */
#about{
  padding: 60px 0;
}
.about-info > div{
  width: 50%;
  height: 200px;
  background-color: var(--color-primary);
  border-radius: 30px;
  padding: 20px;
  padding-left: 40px;
  display: flex;
  align-items: center;
}
.about-left{
  padding-right: 70px;
}
.about-img{
  background-image: url('https://kanakadurgafinance.com/assets/images/fin.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.currency{
  margin-top: 30px;
  padding-top: 30px;
  border-top: 2px solid #9e9e9e;
  display: flex;
  align-items: center;
}
.icon{
  margin-right: 20px;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  padding: 2px;
}
.icon-inner{
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
}
.about-content{
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about-content p{
  margin: 30px 0;
}


/* review */
#review{
  padding-top: 60px;
}
.review-counter{
  margin-bottom: 20px;
}
.review-counter-inner{
  background-color: var(--color-secondary);
  padding: 20px;
  border-radius: 20px;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.review-avatar{
  width: 160px;
  height: 185px;
  object-fit: contain;
  border-radius: 12px;
  margin-right: 30px;
}
.review-item{
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  margin-right: 40px;
  
}
.comitee-designations p {
  text-align: left !important;
}
.review-inner{
  display: flex;
  align-items: center;
}
.review-item .accent-heading{
  margin: 15px 0 8px 0;
}
.review-wrapper{
  padding-right: 70px;
  
}
.slick-dots{
  width: 15px;
  height: 100%;
  position: absolute;
  right: -40px;
  top: 0;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}
.slick-dots li button:before{
  color: var(--color-secondary);
  background-color: var(--color-secondary);
  content: '' !important;
  opacity: 1;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
}
.slick-dots li.slick-active button:before{
  background-color: var(--color-primary);
}


/* footer */
footer{
  padding: 30px;
  margin-top: 40px;
}
.footer-inner{
  background-color: var(--color-secondary);
  border-radius: 30px;
  padding: 60px 60px 30px 60px;
}
.footer-logo{
  width: 100%;
  display: flex;
  justify-content: center;
}
.footer-logo img{
  height: 60px;
  width: auto;
}
.footer-nav{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;

}
.footer-nav li{
  color: white;
}
.footer-nav li a{
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.footer-contact{
  display: flex;
  justify-content: center;
}
.footer-p{
  padding-left: 50px;
}
.footer-contact-item{
  font-size: 25px;
  font-weight: 800;
  color: white;
  margin: 0 30px;
}
.footer-branding{
  
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  padding-top: 30px;
  border-top: 2px solid #9e9e9e;
}
.social-wrapper{
  display: flex;
  margin-top: 20px;
}
.social-item{
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--color-primary);
  margin-right: 10px;
  transition: .3s ease-in-out;
}
.social-item svg{
  width: 20px !important;
  height: 20px !important;
}
.social-item:hover{
  color: white;
  background-color: var(--color-primary);
}
.review-counter-inner{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  
}
.partner-item-home{
  margin: 0 20px;
}
.review-counter{
  width: calc(50% - 20px);
}

.mission-card{
  padding: 40px;
  border-radius: 40px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, .1);
  background-color: white;
}
.about-img-cell{
  height: 190px;
  background-image: url('https://kanakadurgafinance.com/assets/images/kanaka-durga-slider-04.jpg');
  border-radius: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.about-intro-content h2{
  text-transform: none;
  margin: 20px 0;
}
#about-intro{
  padding-top: 50px;
}
.mission-icon{
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
  background-color: var(--color-secondary);
  border-radius: 17px;
  margin-bottom: 30px;
}
.mission-card h2{
 margin-bottom: 15px;
 color: var(--coloor-dark);
}
#mission{
  padding-top: 70px;
}
.heading{
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
#comitee{
  padding-top: 70px;
}
#directors{
  padding-top: 30px;
}
.director-avatar img{
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 25px;
  object-position: top;
}
.director-avatar{
  margin-bottom: 20px;
}
.directors-header{
  
}
.directors-header h2,.comitee-inner h2{
  font-size: 15px !important;
  text-transform: capitalize !important;
}
.directors-header .director-designation{
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
  color: var(--color-primary);
}
.directors-card{
  border-radius: 30px;
  border: 2px solid #f3e9e0;
  background-color: white;
}
.directors-header{
  padding: 30px;
  border-bottom: 2px solid #f3e9e0;
}
.director-body{
  padding: 30px;
}
.comitee-card{
  padding: 30px;
  border: 2px solid #f3e9e0;
  border-radius: 30px;
  background-color: white;
}
.comitee-inner h2{
  margin-bottom: 30px;
}
.comitee-designations{
  margin: 0;
  padding: 0;
}
.comitee-designations li{
  display: flex;
  align-items: center;
  
  padding: 10px 0;
  border-bottom: 1px solid #f3e9e0;
}
.nav-mb{
  display: none !important;
}
.comitee-designations li p:nth-child(2){
  margin-left: 10px;
}
.comitee-designations li:last-child{
  border-bottom: none;
  padding-bottom: 0;
}
.comitee-designations li:first-child{
  padding-top: 0;
}
.comitee-designations li p:first-child{
  color: var(--color-secondary);
}
.service-des{
  margin-bottom: 30px;
  text-align: center;
}
.feature-heading{
  margin-bottom: 15px;
  text-align: center;
  color: var(--color-primary);
}
.service-features-box{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.feature-item{
  margin: 0 15px;
  background-color: white;;
  padding: 10px;
  padding-right: 20px;
  border: 2px solid #f3e9e0;
  border-radius: 35px;
  transition: .3s ease-in-out;
}
.feature-space-y .feature-item{
  margin-top: 15px;
}
.feature-inner{
  display: flex;
  align-items: center;
}
.feature-icon{
  width: 40px;
  height: 40px;
  display: flex;
  background-color: var(--color-secondary);
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 20px;
  transition: .3s ease-in-out;

}
.feature-title{
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  white-space: nowrap;
}
.feature-item:hover{
  background-color: var(--color-secondary);
}
.feature-item:hover .feature-icon{
  background-color: white;
  color: var(--color-primary);
}
.feature-item:hover .feature-title{
  color: var(--color-light);
}
.feature-icon svg{
  width: 20px !important;
  height: 20px !important;
}
.service-cta{
  margin-bottom: 50px;
  justify-content: center;
}
.service-cta a{
  margin: 0 10px;
}
.dropdown-menu li:hover a,.dropdown-item:active ,.dropdown-item.active{
  background-color: var(--color-primary) !important;
}
.service-cta .btn-primary{
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}
.service-cta .btn-primary:hover{
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.image-gallery-thumbnails-wrapper{
  display: none !important;
}
.service-gallery{
  max-width: 900px;
  margin: 0 auto;
}
.service-gallery img{
  border-radius: 220px;
}
.image-gallery-icon:hover {
  color: var(--color-primary);
}
.report-card{
  padding: 30px;
  border-radius: 30px;
  border: 2px solid #f3e9e0;
  background-color: white;
}
.download-report{
  width: 50px;
  height: 50px;
  background-color: var(--color-primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: .3s ease-in-out;
}
.download-report svg{
  width: 25px !important;
  height: 25px !important;
}
.download-report:hover{
  background-color: var(--color-secondary);
}
.partner-item{
  background-color: white;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-radius: 20px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1);
}
.partner-item img{
  height: 80px;
  width: auto;
  margin: 0 auto;
}
.policy-item{
  padding: 10px !important;
  cursor: pointer;
  background-color: var(--color-secondary);
  transition: .3s ease-in-out;
}
.policy-item p{
  font-size: 14px;
  color: white;
}
.policy-item:hover{
  background-color: var(--color-primary);
}
.network-card{
  border: 2px solid #f3e9e0;
  border-radius: 30px;
  background-color: white;
  margin-top: 20px;
}
.network-header{
  padding: 30px;
  border-bottom: 2px solid #f3e9e0;
}
.network-body{
  padding: 30px;
}
.network-footer{
  padding: 20px 30px;
  border-top: 2px solid #f3e9e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.network-icon-btn{
  width: 40px;
  height: 40px;
  background-color: var(--color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  transition: .3s ease-in-out;
  margin-right: 10px;
}
.network-icon-btn:hover{
  background-color: var(--color-primary);
}
.ntwork-left{
  display: flex;
}
#contact{
  padding: 60px 0 70px 0;
}
.map{
  border-radius: 30px;
  overflow: hidden;
}
.field{
  display: flex;
  flex-direction: column;
}
label{
  font-size: 12px;
  text-transform: uppercase;
  color: var(--color-primary);
  margin-bottom: 5px;
  font-weight: 500;
  display: none;
}
input,textarea,select{
  border: 2px solid #f3e9e0;
  border-radius: 25px;
  outline: none;
  padding-left: 15px;
  font-size: 15px;
}
input:hover, textarea:hover,input:focus,textarea:focus,select:hover{
  border-color: var(--color-primary);
}
input,select{
  height: 50px;
}
textarea{
  padding-top: 10px;
  height: 100px;
}
.emi-feature{
  margin: 20px 0;
  padding: 0;
}
.emi-feature path{
  fill: var(--color-primary);
  margin-right: 15px;
}
.emi-feature li span:first-child{
  margin-right: 10px;
}
.loan-apply-wrapper{
  max-width: 700px;
  margin: 0 auto;
  background-color: white;
  padding: 30px;
  border-radius: 15px;
}
.loan-apply-wrapper h1,.loan-apply-wrapper h2{
  text-align: center;
}
.loan-apply-wrapper h1{
  margin-bottom: 30px;
}
#apply-loan{
  padding: 70px 0;
}
.info-box .feature-item{
  margin-bottom: 15px;
}
.filer-row{
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
#jobs{
  padding: 60px 0 70px 0;
}
.av-jobs{
  background-color: white;
  padding: 60px 0;
  border-radius: 30px;
}
.job-wrapper{
  max-width: 900px;
  margin: 0 auto;
}
.filter-item{
  margin: 0 10px;
  height: 30px;
  padding: 0 12px;
  border: 1px solid var(--color-primary);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s ease-in-out;
  cursor: pointer;
}
.filter-item p{
  font-size: 12px;
  font-weight: 500;
  color: var(--color-primary);
  text-transform: uppercase;
  transition: .3s ease-in-out;
}
.active-filter{
  background-color: var(--color-primary);
}
.active-filter p{
  color: white;
}
.filter-item:hover{
  background-color: var(--color-primary);
}
.filter-item:hover p {
  color: white;
}
.feature-item:hover p{
  color: white !important;
}
.job-ineer{
  padding: 30px;
  border-radius: 30px;
  border: 2px solid #f3e9e0;
}
.job-ineer p{
  margin: 20px 0;
}

/* 
loan pages */

#loan-hero,#why-loan,#how-apply{
  padding: 50px 0;
}
.loan-one-image{
  padding-left: 100px;
}
.loan-one-image-inner{
  display: table;
  position: relative;
}
.loan-one-image-inner img{
  max-width: 100%;
  height: auto;
}
@keyframes movehori {
  0%{
    transform: translateZ(0);
  }
  50%{
    transform: translate3d(-10px, 0, 0);
  }
  100%{
    transform: translateZ(0);
  }
}
@keyframes jump {
  0%{
    transform: translateZ(0);
  }
  50%{
    transform: translate3d(0, 10px, 0);
  }
  100%{
    transform: translateZ(0);
  }
}
.loan-sm{
  border: 10px solid white;
  position: absolute;
  right: -100px;
  bottom: 50px;
  filter: (0px 4px 60px rgba(117, 165, 67, 0.12));
  animation: movehori 2s linear infinite;
  max-height: 180px;
}
.loan-shape{
  position: absolute;
  top: 30px;
  right: -80px;
  animation: jump 2s linear infinite;
}
.loan-one-exp{
  position: absolute;
  top: 50px;
  left: -100px;
  padding: 34px 26px 19px;
  text-align: center;
  background-color: var(--color-primary);
  z-index: 2;
}
.loan-one-exp::before{
  content: '';
  width: 101px;
  height: 67px;
  background-color: var(--color-secondary);
  position: absolute;
  left: 0;
  bottom: -67px;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}
.loan-one-bg{
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    mix-blend-mode: multiply;
    opacity: 0.25;
}
.loan-one-exp-bg{
  background-image: url('https://localhost:3000/assets/images/pattern.png');
  top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    mix-blend-mode: multiply;
    opacity: 0.25;
    position: absolute;
}
.loan-one-exp-content{
  position: relative;
}
.loan-one-icon{
  width: 55px;
  height: 55px;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.loan-one-icon svg{
  width: 30px !important;
  height: 30px !important;
}
.loan-f-box{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.loan-f-box .feature-item{
  margin: 0 !important;
}
#why-loan{
  background-color: var(--color-secondary);
}
.why-intro{
  margin-bottom: 30px;
  text-align: center;
}
.process-image{
  margin-bottom: 21px;
  padding-bottom: 46px;
  position: relative;
  width: 100%;
  max-width: 235px;
  text-align: center;
}
.process-image img{
  position: relative;
  width: 129px;
  height: 129px;
  border-radius: 50%;
  z-index: 1;
}
.process-count{
  padding: 4.5px 10px;
  position: relative;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 1;
  font-weight: 700;
  font-size: 14px;
  color: var(--color-light);
  line-height: 1.5;
  text-transform: capitalize;
  background-color: var(--color-primary);
  border-radius: 100px;
  transition: all 500ms ease;
}
.work-process-one__item__shape{
  width: 242px;
  height: 67px;
  position: absolute;
  left: 0;
  bottom: 13px;
  transition: all 500ms ease;
}
.work-process-one__item__shape path{
  stroke: var(--color-primary);
  stroke-width: 3;
  stroke-miterlimit: 10;
  transition: all 500ms ease;
}
.work-process-one__item__circle {
  width: 136px;
  height: 32px;
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  transition: all 500ms ease;
}
.work-process-one__item__circle path {
  fill:#F6F6F8;
  transition: all 500ms ease;
}
@keyframes wobble-horizontal-hover {
  16.65% {
    transform: translateX(5px);
  }
  33.3% {
    transform: translateX(-3px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.error{
  color: red;
  opacity: .8;
}
.status{
  font-size: 13px;
}
.process-item:hover .work-process-one__item__shape{
  animation: wobble-horizontal-hover 1s ease-in-out 1;
}
.process-item:hover .work-process-one__item__circle path{
  fill: var(--color-primary);
}
.toolti{
  fill: var(--color-primary) !important;
  z-index: 55;
  font-size: 20px;
  font-weight: 800;
  font-family: 'optima', sans-serif;
}
.contact-info .feature-icon{
  width: 70px;
  height: 70px;
}
.contact-info svg{
  width: 40px !important;
  height: 40px !important;
}
.contact-info .feature-title{
  font-size: 20px;
  line-height: 26px;
  text-transform: none !important;
}
.map-india path{
  fill: #faba81;
  cursor: pointer;
}
.active-map{
  fill: var(--color-primary) !important;
}
.map-india path:hover{
  fill: #c2610d !important;
}
.success{
  color: green;
  opacity: .7;
}
.nach-model{
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .8);
  backdrop-filter: blur(8px);
}
#ecww-widgetwrapper{
  min-width: 250px;
  
  width: auto;
  height: 600px;
  overflow-y: scroll;
}
#ecww-widget{
  position: relative;
  padding-top: 0;
  padding-bottom: 280px;
  height: 0;
  overflow: hidden;
}
#ecww-more{
  background-color: #333;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px 0;
  color: white;
  text-align: center;
  width: 100%;
  clear: both;
  margin: 0;
  float: left;
}
#ecww-more a{
  background-color: #333;
  color: white;
  text-decoration: none;
  border-bottom: 1px dotted #ccc;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0;
      padding-left: 0;
  }
}
@media only screen and (max-width: 768px) {
  #hero{
    padding: 0;
    padding-top: 20px;
  }
  header{
    padding: 0 20px;
    top: 30px;
  }
  #hero .inner{
    min-height: 400px;
  }
  .hero-main-heading{
    font-size: 30px;
    line-height: 35px;
    margin: 15px 0;
  }
  .container{
    padding-left: 20px;
    padding-right: 20px;
  }
  .craousel-btn{
    top: -180px;
  }
  .about-left{
    padding-right: 0;
  }
  .about-content{
    padding-top: 60px;
  }
  .review-wrapper{
    padding-right: 0;
  }
  footer{
    padding: 20px;
  }
  .footer-inner{
    padding: 60px 20px 30px 20px;
  }
  .footer-p{
    padding: 0;
    padding-top: 20px;
  }
  footer .col{
    width: 100%;
    min-width: 100%;
  }
  footer .row{
    flex-wrap: wrap;
  }
  .widget-title{
    margin-bottom: 15px;
  }
  .footer-branding{
    flex-direction: column;
  }
  nav{
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .page-header-main{
    font-size: 30px;
  }

  .megaenu-service{
    display: none !important;
  }
  .loan-shape,.loan-sm{
    display: none;
  }
  .loan-content{
    padding-top: 30px;
  }
  .process-image{
    margin: 0 auto;
  }
  .process-content{
    text-align: center;

  }
  .process-item{
    margin: 0 !important;
    margin-bottom: 50px !important;
  }
  #page-header{
    padding: 20px 10px 0 10px;
  }
  .service-features-box{
    flex-wrap: wrap;
  }
  .feature-item{
    margin-bottom: 20px;
  }
  .filer-row{
    overflow-x: scroll;
    padding-bottom: 15px;
    padding-left: 200px;
  }
  .filter-item p{
    white-space: nowrap;
  }
  .av-jobs{
    padding: 60px 20px;
  }
  .info-box{
    flex-wrap: wrap;
  }
  #partner{
    margin-top: 70px;
  }
  #partner .secondary-heading{
    margin-bottom: 20px;
    text-align: center;
  }

}
